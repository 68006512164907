import { getHash } from "@pcd/passport-crypto";
import { PCDCollection } from "@pcd/pcd-collection";
import * as base64 from "base64-js";
import stringify from "fast-json-stable-stringify";
import pako from "pako";
import { NetworkFeedApi } from "./FeedAPI.js";
import { FeedSubscriptionManager } from "./SubscriptionManager.js";
export function isSyncedEncryptedStorageV1(
// eslint-disable-next-line @typescript-eslint/no-explicit-any
storage) {
    return storage._storage_version === undefined;
}
export function isSyncedEncryptedStorageV2(
// eslint-disable-next-line @typescript-eslint/no-explicit-any
storage) {
    return storage._storage_version === "v2";
}
export function isSyncedEncryptedStorageV3(
// eslint-disable-next-line @typescript-eslint/no-explicit-any
storage) {
    return storage._storage_version === "v3";
}
export function isSyncedEncryptedStorageV4(
// eslint-disable-next-line @typescript-eslint/no-explicit-any
storage) {
    return storage._storage_version === "v4";
}
export function isSyncedEncryptedStorageV5(
// eslint-disable-next-line @typescript-eslint/no-explicit-any
storage) {
    return storage._storage_version === "v5";
}
export function isSyncedEncryptedStorageV6(
// eslint-disable-next-line @typescript-eslint/no-explicit-any
storage) {
    return storage._storage_version === "v6";
}
/**
 * Deserialize a decrypted storage object and set up the PCDCollection and
 * FeedSubscriptionManager to manage its data.  If the storage comes from
 * an older format which doesn't include subscriptions, then the
 * FeedSubscriptionManager will be empty.
 */
export async function deserializeStorage(storage, pcdPackages, fallbackDeserializeFunction) {
    let pcds;
    let subscriptions;
    if (isSyncedEncryptedStorageV6(storage)) {
        const serializedPCDs = storage.compressedPCDs
            ? decompressStringFromBase64(storage.pcds)
            : storage.pcds;
        pcds = await PCDCollection.deserialize(pcdPackages, serializedPCDs, {
            fallbackDeserializeFunction
        });
        subscriptions = FeedSubscriptionManager.deserialize(new NetworkFeedApi(), storage.subscriptions);
    }
    else if (isSyncedEncryptedStorageV5(storage)) {
        pcds = await PCDCollection.deserialize(pcdPackages, storage.pcds, {
            fallbackDeserializeFunction
        });
        subscriptions = FeedSubscriptionManager.deserialize(new NetworkFeedApi(), storage.subscriptions);
    }
    else if (isSyncedEncryptedStorageV4(storage)) {
        pcds = await PCDCollection.deserialize(pcdPackages, storage.pcds, {
            fallbackDeserializeFunction
        });
        subscriptions = FeedSubscriptionManager.deserialize(new NetworkFeedApi(), storage.subscriptions);
    }
    else if (isSyncedEncryptedStorageV3(storage)) {
        pcds = await PCDCollection.deserialize(pcdPackages, storage.pcds, {
            fallbackDeserializeFunction
        });
        subscriptions = FeedSubscriptionManager.deserialize(new NetworkFeedApi(), storage.subscriptions);
    }
    else if (isSyncedEncryptedStorageV2(storage)) {
        pcds = await PCDCollection.deserialize(pcdPackages, storage.pcds, {
            fallbackDeserializeFunction
        });
        subscriptions = new FeedSubscriptionManager(new NetworkFeedApi());
    }
    else if (isSyncedEncryptedStorageV1(storage)) {
        pcds = new PCDCollection(pcdPackages);
        await pcds.deserializeAllAndAdd(storage.pcds, {
            fallbackDeserializeFunction
        });
        subscriptions = new FeedSubscriptionManager(new NetworkFeedApi());
    }
    else {
        throw new Error(`Unknown SyncedEncryptedStorage version 
      ${storage["_storage_version"]}`);
    }
    return {
        pcds,
        subscriptions,
        storageHash: await getStorageHash(storage)
    };
}
/**
 * Serializes a user's PCDs and relates state for storage.  The result is
 * unencrypted, and always uses the latest format.  The hash uniquely identifies
 * the content, as described in getStorageHash.
 *
 * Sets a default compression threshold of 500,000 bytes. Callers may override
 * with a different value. The `compressedPCDs` flag in {@link SyncedEncryptedStorageV6}
 * will enable {@link deserializeStorage} to process either compressed or uncompressed
 * PCDs.
 */
export async function serializeStorage(user, pcds, subscriptions, options = { pcdCompressionThresholdBytes: 500000 }) {
    const serializedPCDs = await pcds.serializeCollection();
    const shouldCompress = new Blob([serializedPCDs]).size >= options.pcdCompressionThresholdBytes;
    const serializedStorage = {
        pcds: shouldCompress
            ? compressStringAndEncodeAsBase64(serializedPCDs)
            : serializedPCDs,
        subscriptions: subscriptions.serialize(),
        compressedPCDs: shouldCompress,
        self: user,
        _storage_version: "v6"
    };
    return {
        serializedStorage: serializedStorage,
        storageHash: await getStorageHash(serializedStorage)
    };
}
/**
 * Calculates a hash to uniquely identify the given seralized storage.
 */
export async function getStorageHash(storage) {
    return await getHash(stringify(storage));
}
/**
 * Compresses a string and encodes it as a base64 string.
 * @param str the string to compress
 * @returns the compressed string encoded as a base64 string
 */
export function compressStringAndEncodeAsBase64(str) {
    return base64.fromByteArray(pako.deflate(str));
}
/**
 * Decompresses a base64 string and decodes it as a string.
 * @param base64Str the base64 string to decompress
 * @returns the decompressed string
 */
export function decompressStringFromBase64(base64Str) {
    return new TextDecoder().decode(pako.inflate(base64.toByteArray(base64Str)));
}
